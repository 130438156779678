@media only screen and (min-width: 991px) and (max-width: 1024px) {
   .singleScreen
    .colmaxWidthBR {
    max-width: 12rem;
  }
  .singleScreen
    .colmaxWidthTV {
    max-width: 12rem;
  }
  .singleScreen
    .colmaxWidthIE {
    max-width: 12rem;
  }
  .singleScreen
    .colmaxWidthMV {
    max-width: 12rem;
  }
}


/* if there is only one slot-- start */
.singleScreen
  .boxComp-CaptionStyle {
  text-align: center;
  min-width: 8rem;
  font-size: 1.5rem;
  padding-top: 0rem;
}
.singleScreen
  .boxComp-UnitStyle {
  font-size: 1rem;
  padding-top: 1rem;
}
.singleScreen
  .boxComp-ValueStyle {
  font-size: 50px;
}
.singleScreen
  .canvasDivStyle {
  display: flex;
  justify-content: center;
}
/* if there is only one slot-- end */

.navbar-singledevice-guest{
  width: 106%;
  background:black;
}

@media(max-width:1150px){
  .navbar-singledevice-guest{
    width: unset;
    background:black;
  }
}
.main-div-right-sidebar {
  height: 100%;
  z-index: 99;
  /* height: inherit; */
  display: flex;
  align-items: center;
  background: inherit;
}


.right-area1 {
  padding: 0px 0px 0px 7px;
  box-shadow: 0 2px 9px 0 rgb(0 0 0 / 15%);
  height: inherit;
  width: 305px;
  overflow: auto;
}
.bgtransparent {
  background: #1e1e1d
}

#navbarLeft {
z-index: 10;
height: 250px;
/* margin-top: -20px; */
display: flex;
align-items: center;
position: absolute;
left: 0px;
}


.main-div12 {
  height: 100%;
  width: fit-content;
  /* position: fixed; */
  z-index: 99;
  /* height: inherit; */
  display: flex;
  /* align-items: center; */
  background: inherit;
}
.menu {
  height: inherit;
  overflow-x: hidden;
  overflow-y: scroll;
}
.menu::-webkit-scrollbar {
  width: 1em;
}
 
.menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.menu::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.bgtransparent {
  box-shadow: 0 2px 9px 0 rgb(0 0 0 / 15%);
  width: 350px;
  background: #1e1e1de0
}
.sub-div {
  width: fit-content;
}
.menu-items {
  height: inherit;
}
.menubar-toggle {
  margin-top: 15px;
  z-index: 99;
  width: 22px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* right: 0; */
  cursor: pointer;
  background: #ff0 !important;
  border-radius: 0 10px 10px 0;
  box-shadow: inset 2px 0px 5px 0px rgb(0 0 0 / 25%);
}
.menu > p {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.9px;
  height: 60px;
  line-height: 50px;
  border-bottom: 1px solid #88909e;
  padding: 0 10px;
}
.list-elements {
  padding: 15px 0;
  height: calc(100% - 50px);
  overflow-y: auto;
}
.main-div12 li > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: auto;
  height: 40px;
  font-size: 16px;
  letter-spacing: -0.79px;
  padding: 0 10px;
  line-height: 40px;
  border-radius: 2px;
}