.VentSecRowMarginTop {
  margin-top: 1%;
}

@media only screen and (min-width: 1025px) and (max-width: 1250px) {
  /* .VentSecRowMarginTop {
    margin-top: 1rem;
  } */
}

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  /* .VentSecRowMarginTop {
    margin-top: 4rem;
  } */
}

@media only screen and (max-width: 767px) {
  .VentSecRowMarginTop {
    margin-top: 2%;
  }
}

@media only screen and (min-height: 1024px) {
  /* .VentSecRowPadding {
    padding: 75px 10px;
  } */
}

.boxComp-ValueStyle,
.boxComp-UnitStyle,
.boxComp-CaptionStyle {
  color: white;
}

.canvasDivStyle {
  display: flex;
  justify-content: flex-end;
}

.BoxComp-mainDiv {
  background: black;
  min-width: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem;
}

.firstRowButtons {
  color: white;
  padding: 15px !important;
  height: 85%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonCaption {
  color: white;
  font-size: 15px;
}
.buttonValue {
  color: white;
  font-size: 35px;
}
.buttonUnit {
  color: white;
  font-size: 15px;
}
.FstbuttonCaption {
  color: white;
  font-size: 20px;
}
.FstbuttonValue {
  color: white;
  font-size: 18px;
}

@media only screen and (max-width: 991px) {
  .boxComp-CaptionStyle {
    text-align: center;
    min-width: 8rem;
    font-size: 1.5rem;
    padding-top: 0rem;
  }
  .boxComp-UnitStyle {
    font-size: 1rem;
    padding-top: 1rem;
  }
}

@media only screen and (min-width: 991px) {
  .BoxComp-mainDiv {
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
  }

  .boxComp-CaptionStyle {
    text-align: center;
    min-width: 5rem;
    font-size: 14px;
    padding-top: 0rem;
  }
  .boxComp-UnitStyle {
    font-size: 12px;
    padding-top: 1rem;
  }
  .boxComp-ValueStyle {
    font-size: 35px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1024px) {
  .BoxComp-mainDiv {
    min-width: 4rem;
    margin: 0px;
  }
  .md3-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
  .custfontSize {
    font-size: 12px !important;
  }
  .colmaxWidthBR {
    max-width: 4.5rem;
  }
  .colmaxWidthTV {
    max-width: 4rem;
  }
  .colmaxWidthIE {
    max-width: 4.5rem;
  }
  .colmaxWidthMV {
    max-width: 4rem;
  }
  .secRowColpadleft {
    padding-left: 0px;
  }
  .buttonCaption {
    color: white;
    font-size: 14px;
  }
  .buttonValue {
    color: white;
    font-size: 30px;
  }
  .buttonUnit {
    color: white;
    font-size: 14px;
  }
  .FstbuttonCaption {
    color: white;
    font-size: 18px;
  }
  .FstbuttonValue {
    color: white;
    font-size: 14px;
  }

  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
    .colmaxWidthBR {
    max-width: 12rem;
  }
  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
    .colmaxWidthTV {
    max-width: 12rem;
  }

  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
    .colmaxWidthIE {
    max-width: 12rem;
  }

  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
    .colmaxWidthMV {
    max-width: 12rem;
  }
}

/* if there is only one slot-- start */
.col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
  .boxComp-CaptionStyle {
  text-align: center;
  min-width: 8rem;
  font-size: 1.5rem;
  padding-top: 0rem;
}
.col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
  .boxComp-UnitStyle {
  font-size: 1rem;
  padding-top: 1rem;
}
.col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
  .boxComp-ValueStyle {
  font-size: 50px;
}

.col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
  .canvasDivStyle {
  display: flex;
  justify-content: center;
}
/* if there is only one slot-- end */

.responsiveTopMargin {
  margin-top: 0.5%;
  padding-right: 0px;
}
.desktop-screen {
  margin-top: 6%;
}

@media (max-width: 1229px) {
  .responsiveTopMargin {
    margin-top: 1.1%;
  }
}
@media (max-width: 1180px) {
  .responsiveTopMargin {
    margin-top: 1.3%;
  }
}
@media (max-width: 1139px) {
  .responsiveTopMargin {
    margin-top: 1.5%;
  }
}
@media (max-width: 1100px) {
  .responsiveTopMargin {
    margin-top: 2%;
  }
}
@media (max-width: 1000px) {
  .responsiveTopMargin {
    margin-top: 2.5%;
  }
}
@media (max-width: 956px) {
  .responsiveTopMargin {
    margin-top: 3%;
  }
}
@media (max-width: 800px) {
  .responsiveTopMargin {
    margin-top: 4%;
  }
}
@media (max-width: 627px) {
  .responsiveTopMargin {
    margin-top: 6%;
  }
}
