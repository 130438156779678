@media only screen and (max-width: 600px) {
  .custPadding {
    /* padding-left: 45px; */
  }}


@media only screen and (min-width: 991px) {
  .custPadding {
    /* padding-left: 0px; */
  }
}

@media only screen and (max-width: 991px) {
  .custPadding {
    /* padding-left: 45px; */
  }


  .homeIconMarginLeft {
    margin-left: 50px;
  }
}

.cust-icon-wrapper {
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  margin-top: -13px;
  margin-left: -8px;
  position: absolute;

  float: left;
  box-shadow: 0 4px 20px 0px rgba(241, 240, 240, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
  background: linear-gradient(60deg, #808080, #808080);
}
.cust-icon-low-icon {
  background: linear-gradient(60deg, #80488a, #300a7dc2) !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 47, 255, 0.192) !important;
}
@media only screen and (max-width: 600px) {
  .sidebarCustPadding {
    padding-left: 0px;
    padding-top: 0px;
  }
}

@media only screen and (min-width: 600px) {
  .sidebarCustPadding {
    padding-left: 8px;
    padding-top: 15px;
  }
}
@media only screen and (min-width: 1025px) {
  .cardMargin {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .cardMargin {
    margin-bottom: 50px;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .card-cat-custMinHeight {
    min-height: 50px;
  }
}

/* ------Glowing Property---------------------- */
/* .glow {
      color: black;
      text-shadow:
      0 0 2px red,
       0 0 4px red;
    } */
.glow {
  -webkit-animation: glow 0.2s ease-in-out infinite alternate;
  -moz-animation: glow 0.2s ease-in-out infinite alternate;
  animation: glow 0.2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 1px transparent, 0 0 1.2px #f57d7d, 0 0 1.5px #f3b5b1,
      0 0 1.8px #ef6487, 0 0 1.9px #ef4570, 0 0 2px #f12c5e, 0 0 2.3px #f70b47;
  }
}
