.alarm-list {
  margin-top: 1px;
  padding: 2px;
  position: absolute;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.alarm-custome-style {
  display: flex;
  justify-content: center;
  justify-items: center;
}
.high-alarm {
  background: linear-gradient(182deg,#ba567270, #D61A3C);
}
.low-alarm {
  background: linear-gradient(182deg, #a72abd73,#a72abd);
}
.medium-alarm {
  background: linear-gradient(182deg,#ff9e0f6e, #ff9e0f);
}
.no-alarm {
  background: linear-gradient(360deg, #55b5598a, #64c168);
}
.grayColr{
  background: gray;
}

.contrl-colr{
  background: #333232;
}

.list-group-flush .list-group-item {
  /*   background: #dc35455c !important; */
  color: #f7f3f3 !important;
}
.cotrol-container {
  display: flex;
  padding: 7px 20px;
  color: white;
  width: 100%;
  justify-content: flex-end;
}
