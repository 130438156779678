.hardware-panel>.hardware-content{
    margin-top: 65px;
}

.main-panel{
    width: 100%;
}
.register .form-control.cust-input{
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: none;
    border-radius: 5px;
    padding-left: 15px;
    cursor: not-allowed;
}

.register .form-control.cust-input1{
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: white;
    border-radius: 5px;
    padding-left: 15px;
}

.register .labelstyle{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
