.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.meterGraph {
  width: 10%;
  height: 90%;
}
.Graph {
  height: 90%;
  width: 90%;
}
.flowlabelMT {
  margin-top: 120px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.background {
  color: #282c34;
}

.btn-overlay {
  position: absolute;
  top: 10;
  left: 1;
  background-color: rgb(5, 187, 5);
  opacity: 1;
  display: inline-block;
  width: 300%;
  height: 70%;
  border-radius: 0px;
}

.dropdownvalues {
  width: 300px;
  /* margin-left: 40px; */
  /* margin-top: 20px; */
  background-color: grey;
}

.nav-icon {
  padding-right: 20px;
  padding-top: 15px;
  float: right;
}
.custome-nav {
  margin-left: -19px;
  margin-right: -19px;
}
.custome-nav > div {
  background-color: #919191;
  border: 1px solid #bab3b3;
}
.custome-nav .btn-lg {
  width: -webkit-fill-available;
}

.setting {
  background-color: rgb(5, 187, 5);
  opacity: 2;
  width: 50px;
  height: 40px;
  border-radius: 0px;
}

.button-1 {
  position: absolute;
  top: 90px;
  left: 20px;
  background-color: rgb(209, 231, 7);
  opacity: 2;
  display: inline-block;
}

.button-2 {
  /* position: absolute; */
  /* top: 90px; */
  /* left: 20px; */
  background-color: gray;
  /* opacity: 2; */
  /* display: inline-block; */
  min-height: 40px;
  width: 130px;
}

.settings {
  background-color: gray;
  opacity: 2;
  min-height: 40px;
  font-size: 14px;
  width: 89%;
  padding: 0px;
}

.value-header {
  color: white;
  font-size: 96%;
}

.value-header2 {
  color: white;
  font-size: 10px;
}

.value {
  color: white;
  font-size: 223%;
}

.value2 {
  color: white;
  font-size: 16px;
}

.values-unit {
  color: white;
  font-size: 15px;
}
.values-unit2 {
  color: white;
  font-size: 12px;
}

.button-3 {
  background-color: grey;
  height: 90px;
  width: 100%;
}

.button-4 {
  background-color: grey;

  width: 50%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.heading {
  color: white;
  font-size: 30px;
  text-align: center;
}
/* .col-md-8 {
  margin-top: 80px;
} */
.valuessss {
  text-align: center;

  /* color: red; */
}
.maindiv {
  width: 95%;
  height: 55vh;
  margin-left: 30px;
  overflow-y: auto;
  overflow-x: hidden;
}
.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  background: #000;
  margin-top: 1%;
}

.secRowMarginTop {
  margin-top: 3rem;
}
@media only screen and (min-width: 1025px) and (max-width: 1250px) {
  .secRowMarginTop {
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .secRowMarginTop {
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 499px) {
  .secRowMarginTop {
    margin-top: 0.5rem;
  }
}

@media only screen and (min-height: 1024px) {
  .secRowPadding {
    padding: 75px 10px;
  }
}

.scrollDisabled {
  position: fixed;
  margin-top: 0;
  width: 100%;
}
.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.font-issue {
  /* text-rendering: optimizeLegibility;
  font-family: "System"; */
  color: White;
  /* font-size: 3.5rem;
  line-height: 2px; */
}
.scrollDisabled {
  position: fixed;
  margin-top: 0;
  width: 100%;
}
.homescreen-cus-navbar-style {
  /* right: 0; */
  background: black;
  /* 
  position: absolute;
  width: 102%;
  right: 0;
  top: 0;
  left: 0; */
}
/* .homescreen-middle-row {
  margin-top: 4%;
} */
.homescreen-card-body-hidden-overflow {
  overflow-x: hidden;
}

.vent-dashboard .remove-card-body-padding {
  /*  padding: 0px; */
}

.removeMargin {
  margin: 0 !important;
}
.removePadding {
  padding: 0 !important;
}
.addVerticalPadding {
  padding-top: 1px !important ;
  padding-bottom: 1px !important;
}
.addRigtPadding {
  padding-right: 2px !important ;
}

@media only screen and (max-width: 575px) {
  .custPopupOverflow {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

/* -------------------------------------------------- */

/* @media only screen and (min-width: 1025px) and (max-width: 1250px) {
  .VentSecRowMarginTop {
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .VentSecRowMarginTop {
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 499px) {
  .VentSecRowMarginTop {
    margin-top: 0.5rem;
  }
}

@media only screen and (min-height: 1024px) {
  .VentSecRowPadding {
    padding: 75px 10px;
  }
}

@media only screen and (max-width:991px) {
  .boxComp-CaptionStyle{
    text-align: center;
    color: White;
    min-width: 8rem;
    font-size: 1.5rem;
    padding-top: 0rem
}
}
.boxComp-ValueStyle{
  color: white;
}
.boxComp-CaptionStyle{
  color: white;
}
@media only screen and (min-width:991px) {
  .boxComp-CaptionStyle{
    text-align: center;
    color: White;
    min-width: 8rem;
    font-size: 1.5rem;
    padding-top: 0rem
  }
  }  */
/* ipad Graph------------------------------------------------ */
.admin-console-table tbody tr > td {
  padding: 12px;
}

@media only screen and (max-width: 991px) {
  .custPagePadding {
    /* padding-left: 55px; */
  }
}

.card .card-body.genrl-tab .form-group {
  margin: 0px;
}

.location-tab-card.card {
  margin: 0px !important;
}

.MuiDialogContent-root {
  padding: 0px !important;
}
.MuiTabs-flexContainer {
  background: white;
  color: black;
}

.vent-details-dialog.card {
  margin: 0px !important;
}

.vent-details-dialog label {
  font-size: 13px;
}

.add-new-vent-dialog.card {
  margin: 0px !important;
}

.add-new-vent-dialog label {
  font-size: 13px;
}

.MuiTypography-h6 {
  font-size: 18px !important;
}

.table thead th {
  font-weight: 600;
}

.vent-dashboard > div {
  background-color: black;
  border: 1px solid white;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .value-header2 {
    color: white;
    font-size: 15px;
  }
  .value2 {
    color: white;
    font-size: 20px;
  }
  .values-unit2 {
    color: white;
    font-size: 15px;
  }
}

@media only screen and (min-width: 991px) {
  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
    .value-header2 {
    color: white;
    font-size: 15px;
  }
  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
    .value2 {
    color: white;
    font-size: 20px;
  }
  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
    .values-unit2 {
    color: white;
    font-size: 15px;
  }
}

@media only screen and (min-width: 1400px) {
  .value-header2 {
    color: white;
    font-size: 15px;
  }
  .value2 {
    color: white;
    font-size: 20px;
  }
  .values-unit2 {
    color: white;
    font-size: 15px;
  }
}
@media only screen and (min-width: 1024px) {
  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
    .CanvasYaxis {
    max-width: 60px;
  }
  .VentSecRowPadding.responsiveTopMargin.single
    .col-lg-10.col-md-10.col-sm-12.col-12
    .CanvasYaxis {
    max-width: 60px;
  }
  .Graph {
    position: relative;
    left: -13px;
  }
  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding .Graph {
    position: relative;
    left: -65px;
  }

  .VentSecRowPadding.responsiveTopMargin.single
    .col-lg-10.col-md-10.col-sm-12.col-12
    .Graph {
    position: relative;
    left: -65px;
  }
  .VentSecRowPadding.responsiveTopMargin.single
    .col-lg-10.col-md-10.col-sm-12.col-12
    .yaxis-labels {
    font-size: 14px;
  }
}

.yaxis-labels {
  font-size: 13px;
  /* width: 90px; */
  color: #ffffffa6;
  transform: rotateZ(-90deg);
}
.col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
  .yaxis-labels {
  font-size: 14px;
}

@media only screen and (max-width: 1024px) {
  .yaxis-labels {
    margin-left: -15px;
  }
  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
    .yaxis-labels {
    margin-left: 0px;
  }
  .VentSecRowPadding.responsiveTopMargin.single
    .col-lg-10.col-md-10.col-sm-12.col-12
    .yaxis-labels {
    margin-left: 0px;
  }

  .Graph {
    position: relative;
    left: 0px;
  }
  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding .Graph {
    position: relative;
    left: -20px;
  }

  .VentSecRowPadding.responsiveTopMargin.single
    .col-lg-10.col-md-10.col-sm-12.col-12
    .Graph {
    position: relative;
    left: -20px;
  }
}
@media only screen and (max-width: 768px) {
  .Graph {
    position: relative;
    left: 0px;
  }
}

/* removing black button outline from everywhere */
button:focus {
  outline: none !important;
  outline-offset: none !important;
}

/* -----Idle Popup----------- */
/* .mark2{
  background: white;
} */
/* @media only screen and (max-width: 1024px) {
  .mark2{
    margin-top: -60px;
  }
  .mark1{
    margin-top: -60px;
  }
}
@media only screen and (max-width: 768px) {
  .mark2{
    margin-top: -60px;
  }
  .mark1{
    margin-top: -60px;
  }
} */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .CustMargTopForPVC {
    margin-top: 12%;
  }
}

@media only screen and (min-height: 1024px) {
  .CustMargTopForPVC {
    margin-top: 12%;
  }
}
.glow-circlular {
  font-size: 80px;
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 0px white, -7px -11px 50px white, 9px -4px 44px white,
    1px 15px 79px #d0cac2, -6px 14px 65px #f7f7f7;
    cursor: pointer;
}

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding .Graph {
    position: relative;
    left: -40px;
  }

  .VentSecRowPadding.responsiveTopMargin.single
    .col-lg-10.col-md-10.col-sm-12.col-12
    .Graph {
    position: relative;
    left: -40px;
  }
}
@media only screen and (max-width: 600px) {
  .meterGraph {
    width: 20%;
  }
  .Graph {
    width: 80%;
  }
  .col-md-12.col-lg-12.col-xs-12.col-xl-12.removePadding.addRigtPadding
    .yaxis-labels {
    margin-left: -15px;
  }
  .VentSecRowPadding.responsiveTopMargin.single
    .col-lg-10.col-md-10.col-sm-12.col-12
    .yaxis-labels {
    margin-left: -15px;
  }
  .flowlabelMT {
    margin-top: 60px;
  }
}

.rotateObj {
  height: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotateObj h2 span {
  font-size: 13px;
  height: 100%;
  display: inline-block;
  /* color: #ffffffa6; */
  transform: rotateZ(-90deg);
  text-align: center;
  width: max-content;
}

.active-pressure {
  color: #80ff00cf;
}
.active-flow {
  color: #ffff00cf;
}

.active-volume {
  color: #ff0000cf;
}

.ZoomCanvasIconPosition {
  position: absolute;
  right: -26px;
  top: 0px;
}

/* @media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ZoomCanvasIconPosition{
  right:0px;
  top: 0px; 
}
} */
@media only screen and (max-width: 768px) {
  .ZoomCanvasIconPosition {
    right: -10px;
    top: -1px;
  }
}

@media only screen and (min-width: 1300px) {
  .ZoomCanvasIconPosition {
    right: -20px;
    top: -0px;
  }
}
.add-hospital-legend{
  font-size: 15px;
  font-weight: 600;
  padding: 0px 10px;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 600;
  margin: 35px 0px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px dashed #333;
}

.separator:not(:empty)::before {
  margin-right: .75em;
}

.separator:not(:empty)::after {
  margin-left: .75em;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.MuiTab-wrapper{
  text-transform: none;
font-size: 16px;
}

.capitalize{
  text-transform:capitalize
}

.watchlistcurve.time-text{
  position: absolute; 
  bottom: -3px;
  left: 47%;
  color: white;
  letter-spacing: 1px;
}

@media only screen and (max-width: 767px) {
  .watchlistcurve.time-text{
    display: none;
  }
}