.css-tlfecz-indicatorContainer {
  display: none !important;
}
.label-text-size {
  font-size: 18px !important;
  font-weight: bold !important;
}

/* backgroundColor: "black",
                height: "600px",
                width: "1000px",
                paddingTop: "2px",
                marginLeft: "15px",
     */
.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-1pahdxg-control {
  background: black !important;
}

.VentSecRowMarginTop {
  margin-top: 1%;
}

.venttext {
  color: yellow;
  font-size: 50px;
}

.ventitem1 {
  padding-top: 50px;
  margin-left: 100px;
  font-size: 20px;
}
.ventitem2 {
  padding-top: 50px;
  margin-left: 20px;
  font-size: 20px;
}

.btnconfirm {
  background-color: grey;
}

.btncancle {
  background-color: grey;
  width: 150px;
  margin-left: 50px;
}

.btntext {
  font-size: 20px;
  color: white;
}
@media only screen and (min-width: 1025px) and (max-width: 1250px) {
  .venttext {
    color: yellow;
    font-size: 50px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .venttext {
    color: yellow;
    font-size: 50px;
  }
  .popup-content {
    height: 50% !important;
    width: 75% !important;
  }
  .ventmode {
    display: flexbox;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1024px) and (orientation: landscape) {
  .venttext {
    color: yellow;
    font-size: 50px;
  }
  .popup-content {
    height: 60% !important;
    width: 75% !important;
  }
  .ventmode {
    display: flexbox;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .popup-content {
    width: 70% !important;
  }
  .venttext {
    color: rgb(133, 0, 185);
    font-size: 50px;
  }
  .ventmode {
    display: flexbox;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media (max-width: 1000px) {
  .venttext {
    color: yellow;
  }
  .ventmode {
    display: flexbox;
    flex-wrap: wrap;
    align-content: center;
  }
}
@media (max-width: 956px) {
  .venttext {
    color: yellow;
    font-size: 40px;
  }
  .ventitem {
    padding-top: 1px;
    margin-left: 45px;
    font-size: 20px;
  }
  .popup-content {
    width: 95% !important;
    height: 90% !important;
  }
}
@media (max-width: 800px) {
  .venttext {
    color: yellow;
  }
  .popup-content {
    height: 40% !important;
    width: 95% !important;
  }
}
@media only screen and (max-width: 627px) {
  .venttext {
    color: yellow;
    font-size: 30px !important;
  }
  .ventitem {
    margin-left: 45px;
    font-size: 15px;
  }
  .popup-content {
    height: 90% !important;
    width: 90% !important;
  }
  .ventmode {
    display: flex;
  }
  .btnconfirm {
    background-color: grey;
    width: 250px;
  }

  .btncancle {
    background-color: grey;
    width: 250px;
  }
}

@media only screen and (max-width: 568px) and (min-width: 320px) {
  .venttext {
    color: yellow;
    font-size: 30px !important;
  }
  .ventitem {
    padding-top: 10px;
    margin-left: 20px;
    font-size: 15px;
  }
  .popup-content {
    height: 95% !important;
    width: 95% !important;
  }
}
@media only screen and (max-width: 640px) and (min-width: 360px) {
  .venttext {
    color: yellow;
    font-size: 25px !important;
  }
  .btntext {
    font-size: 15px;
    color: white;
  }
  .ventitem {
    margin-left: 45px;
    font-size: 15px;
  }
  .popup-content {
    height: 90% !important;
    width: 90% !important;
  }
  .ventmode {
    display: flex;
  }
  .btnconfirm {
    background-color: grey;
    width: 250px;
    margin-left: 45px;
  }

  .btncancle {
    background-color: grey;
    width: 250px;
    margin-left: 45px;
  }
}

@media only screen and (max-width: 757px) and (min-width: 630px) {
  .venttext {
    color: yellow;
    font-size: 40px !important;
  }

  .ventmode {
    display: block;
  }

  .ventitem {
    margin-left: 20px;
    font-size: 20px;
  }
  .popup-content {
    height: 90% !important;
    width: 95% !important;
  }

  .row {
    display: flex;
    flex-wrap: nowrap;
  }
  .btnconfirm {
    background-color: grey;
    width: 200px;
    margin-left: 35px;
  }

  .btncancle {
    background-color: grey;
    width: 200px;
    margin-left: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
  .venttext {
    color: yellow;
    font-size: 40px !important;
  }
  .row {
    flex-wrap: nowrap;
  }
  .ventmode {
    display: block;
  }

  .ventitem {
    margin-left: 25px;
    font-size: 15px;
  }
  .popup-content {
    height: 90% !important;
    width: 95% !important;
  }

  .row {
    display: flex;
    flex-wrap: nowrap;
  }

  .btnconfirm {
    margin-top: -15% !important;
    background-color: grey;
    width: 150px;
    margin-left: 85px;
  }

  .btncancle {
    margin-top: -15% !important;
    background-color: grey;
    width: 150px;
    margin-left: 15px;
  }
}
