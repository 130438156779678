/*========================================================================

Login Page Start Here

=========================================================================*/

@font-face {
    font-family: Typo_Round_Bold;
    src: url(./fonts/Typo_Round_Bold.otf);
  }
  
  @font-face {
    font-family: Typo_Round_Regular;
    src: url(./fonts/Typo_Round_Regular.otf);
  }
  .login
  {
    font-family: Typo_Round_Bold;
  }
  .login
  ::placeholder { 
     color: #000!important;
    opacity: 1; 
  
  }
  
  .own-code-login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    min-height: 100vh;
    z-index: 1;
    padding: 20px 15px 20px;
    background-image: linear-gradient(#ffffff,#d6d6d6, #000);
  }
  
  .own-code-login .own-checkbox-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 30px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  
  }
  .reset-pass-text{
    font-family: Typo_Round_Bold;
  }
  
  .own-code-login .own-content {
    max-width: 340px;
    width: 100%;
  }
  
  .own-code-login .own-header {
    text-align: center;
    margin-bottom: 50px; 
  }
  
  .own-code-login .own-logo {
      display: block;
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    max-width: 40vw;
  }
  
  .logo-resize {
      width: 40%;
  }
  
  @media only screen and (max-width: 991px) {
    .own-code-login .own-logo {
      margin-bottom: 60px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .own-code-login .own-logo {
      margin-bottom: 64px;
    }
  
    .logo-resize {
     width: 100%;
  }
  }
  
  @media only screen and (max-width: 575px) {
    .own-code-login .own-logo {
      margin-bottom: 110px;
    }
  
    .logo-resize {
      width: 100%;
    }
  
    .own-code-login
    {
      padding: 0px 15px 20px;
    }
  }
  
  .own-code-login .own-form p {
    font-size: 20px;
    color: #fff;
  }
  
  @media only screen and (max-width: 767px) {
    .own-code-login .own-form p {
      text-align: center;
    }
  }
  
  .own-code-login .own-form .form-group {
    position: relative;
    z-index: 1;
  }
  
  .own-code-login .own-form .form-group .field-icon {
    position: absolute;
    z-index: 1;
    right: 19px;
    bottom: 18px;
    font-size: 14px;
    color: #000;
  }
  
  .own-style-line a {
      color: #fff;
      font-family: Typo_Round_Regular;
      font-size: 18px;
  }
  
  .own-style-line a:hover {
      text-decoration: none;
  }
  
  .own-code-login .own-form .form-group .field-icon:before {
    padding: 17px 10px;
  }
  
  .own-code-login .own-form .form-control {
      height: 50px;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-bottom: 3px solid#000;
      border-top: none;
      border-left: none;
      border-radius: none!important;
      border-right: none;
      padding: 10px 15px 2px 15px;
      background-color: transparent;
      /* color: #fff; */
  }
  
 
  
  .own-code-login .own-form input::-webkit-input-placeholder {
    color: #bebebe;
    font-size: 18px;
    font-weight: 300;
  }
  
  .own-code-login .own-form input::-moz-placeholder {
    color: #bebebe;
    font-size: 18px;
    font-weight: 300;
  }
  
  .own-code-login .own-form input:-moz-placeholder {
    color: #bebebe;
    font-size: 18px;
    font-weight: 300;
  }
  
  .own-code-login .own-form input:-ms-input-placeholder {
    color: #bebebe;
    font-size: 18px;
    font-weight: 300;
  }
  
  .own-code-login .own-btn-black {
      min-height: 55px;
    font-family: Typo_Round_Regular;
    cursor: pointer;
    display: inline-block;
    font-size: 26px;
    font-weight: 500;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    border: 0;
    color: #fff;
    border-radius: 8px;
    background-color: #000;
    padding: 8px 30px;
    margin: 30px 0px 10px 0px;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
  }
  
  .own-code-login .own-btn-black:hover { 
    background-color: #000;
    border-color: #000;
  }
  
  .own-code-login .own-btn-black:focus {
    outline: none;
  }
  
  .own-code-login .switcher-text {
    color: #b6b6b6;
    font-size: 15px;
    margin-top: 5px;
    display: block;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .own-code-login .switcher-text:last-child {
    margin-right: 0;
  }
  
  .own-code-login .switcher-text:hover {
    color: #e6e6e6;
  }
  
  .own-code-login .switcher-text2 {
    color: #d4d4d4;
    font-size: 15px;
    margin-top: 5px;
    margin-left: 2px;
  display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .own-code-login .switcher-text2:last-child {
    margin-right: 0;
  }
  
  .own-code-login .switcher-text2:hover {
    color: #e6e6e6;
  }
  
  .own-code-login .own-style-line {
    overflow: hidden;
    text-align: center;
  }
  
  .own-code-login .own-style-line h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 30px;
    font-size: 20px;
    color: #fff;
    display: inline-block;
    position: relative;
    padding: 0 25px;
    z-index: 1;
  
  }
  
  .own-footer {
     margin-top: 20px;
  }
  
  .own-code-login .own-footer {
    text-align: center;
  }
  
  .own-code-login .own-footer p {
    color: #b6b6b6;
  }
  
  .own-input-cs {
      /* background: url(../../img/username-icon.png); */
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
      background:none;
      background-repeat: no-repeat;
      background-position: left;
      background-size: 10%;
  }
  
  .own-input-cs1 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
      /* background: url(../../img/password-icon.png); */
      background:none;
      background-repeat: no-repeat;
      background-position: left;
      background-size: 10%;
  }

.login 
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {

  transition: background-color 5000s ease-in-out 0s;
   background-color:transparent !important;
}

.login 
.form-control:invalid {
    background-image: none !important;
}
.login 
.form-control{
    background-color: transparent !important;
    border-radius: 0px!important;
}

.input-img-dim{
width: 32px;
height: 32px;
}
.toggle-icon-pos{
  display: flex;
  justify-content: center;
  align-items: center;
  /* justify-content: flex-end; */
  /* margin-top: -30px;
  margin-right: 15px; */
}
.login 
.error-pgrph{
   color:#e60505 !important;
   font-size: 14px !important;
   text-align: left;
   margin-bottom: 0px;
}

#password::-moz-placeholder { 
  line-height: 35px;
 }

 #Username::-moz-placeholder { 
  line-height: 35px;
 }